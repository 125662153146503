import requests from "./axios"; //引入二次封装的axios

// 获取当前区块高度
export const getBlockHeight = (params) => requests({
    url: `/blockChain/trace_source/max_number`,
    method: 'get',
    params
});

// 已上链分类数据统计
export const type_count = (params) => requests({
    url: `/blockChain/trace_source/type_count`,
    method: 'get',
    params
});

// 根据分类搜索
export const type_page = (params) => requests({
    url: `/blockChain/trace_source/type_page`,
    method: 'get',
    params
});

// 分页查询溯源数据
export const queryType_page = (params) => requests({
    url: `/blockChain/trace_source/page`,
    method: 'get',
    params
});

// 查询溯源详情
export const getSourceDetail = (params) => requests({
    url: `/blockChain/trace_source/id`,
    method: 'get',
    params
});

// // 数据详情查询 弃用
// export const getDataDetail = (params) => requests({
//     url: `/blockChain/trace_source/data_detail`,
//     method: 'get',
//     params
// });

// keyCode查询溯源详情 
export const getSourceDetailByKeyCode = (params) => requests({
    url: `/blockChain/trace_source/keyCode`,
    method: 'get',
    params
});

